<template>
  <v-row>
    <div>
      <v-row>
        <v-col>
          <v-overlay :absolute="absolute" :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-col>
      </v-row>

      <v-snackbar
        centered
        absolute
        transition="slide-y-transition"
        multi-line
        color="success"
        v-model="courseheaderFeedback"
      >
        <v-row>
          <v-col cols="2">
            <v-icon x-large>mdi-check-circle</v-icon>
          </v-col>
          <v-col>
            <div>Success</div>
            <div>New course created...</div>
          </v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="courseheaderFeedback = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-col>
      <v-row>
        <v-col>
          <v-btn
            class="ma-2 secondary--text font-weight-bold"
            to="/course_management/courses"
            color="primary"
          >
            COURSE LIST
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-row>
                  <v-col cols="12" lg="4">
                    <v-row>
                      <v-col>
                        <v-text-field
                          :rules="[(v) => !!v || 'Course Code is required']"
                          label="Course Code"
                          class="rounded-0"
                          outlined
                          v-model="course_code"
                          @keyup="duplicateCourse"
                          :messages="checkingText"
                          :error-messages="error_msgs"
                          :loading="isCourseAvailable"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          hide-details="auto"
                          multiple
                          outlined
                          class="rounded-0"
                          v-model="programs"
                          :items="getters_program"
                          item-text="program_name_certificate"
                          item-value="id"
                          label="Program(s)"
                          :rules="[
                            (v) =>
                              v.length > 0 ||
                              'At least one program is required',
                          ]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="8">
                    <v-row>
                      <v-col>
                        <v-text-field
                          hide-details="auto"
                          :rules="[(v) => !!v || 'Course name is required']"
                          class="rounded-0"
                          label="Course Name"
                          v-model="course_name"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col lg="3">
                        <v-text-field
                          hide-details="auto"
                          type="number"
                          class="rounded-0"
                          :rules="[(v) => !!v || 'Credit hours is required']"
                          label="Credit Hours"
                          v-model.number="credit_hours"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          hide-details="auto"
                          v-model="course_description"
                          label="Course Description"
                          class="rounded-0"
                          outlined
                          :rules="[
                            (v) => !!v || 'Course Description is required',
                          ]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" lg="2" align="center" justify="center">
                    <v-btn
                      block
                      large
                      color="primary"
                      v-if="getters_abilities.includes('create_course_access')"
                      @click="saveCourseBtn"
                      :disabled="!valid"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-row>
</template>
<script>
  import { getCurrentInstance, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Error from "@/components/Error/Error";

  export default {
    components: { Error },
    setup() {
      const vm = getCurrentInstance();
      const { getProgram, saveCourse, signOut } = useActions([
        "getProgram",
        "saveCourse",
        "signOut",
      ]);

      const {
        getters_program,
        getters_savecourse,
        getters_courses,
        getters_abilities,
      } = useGetters([
        "getters_program",
        "getters_savecourse",
        "getters_courses",
        "getters_abilities",
      ]);

      getProgram();

      const form = ref(null);

      const courseForm = reactive({
        valid: true,
        course_code: null,
        course_name: null,
        programs: [],
        credit_hours: null,
        course_description: null,
        checkingText: "",
        error_msgs: "",
        isCourseAvailable: false,
        courseBasket: [],
        absolute: true,
        overlay: false,
        courseheaderFeedback: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      let {
        courseheaderFeedback,
        error_msgs,
        course_name,
        programs,
        credit_hours,
        course_description,
        course_code,
        checkingText,
        isCourseAvailable,
        courseBasket,
        overlay,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
      } = toRefs(courseForm);

      const saveCourseBtn = () => {
        if (form.value.validate()) {
          overlay.value = true;
          deleteResponse.value = false;
          courseBasket.value = {
            course_code: course_code.value,
            course_name: course_name.value,
            programs: programs.value,
            credit_hours: credit_hours.value,
            course_description: course_description.value,
          };
          saveCourse(courseBasket.value)
            .then(() => {
              form.value.reset();
              overlay.value = false;
              courseheaderFeedback.value = true;
            })
            .catch((e) => {
              overlay.value = false;
              deleteResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                msgBody.value = "This action is unauthorized";
              } else if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              } else {
                error_msgs.value = "Course Information already exists";
              }
            });
        }
      };

      const duplicateCourse = debounce(() => {
        if (!getters_courses.value.length) {
          checkingText.value = "";
          isCourseAvailable.value = false;
        } else {
          if (!course_code.value) return;
          isCourseAvailable.value = true;

          checkingText.value = "checking availability...";

          let ifcourseExist = getters_courses.value.some(
            (code) =>
              code.course_code.toUpperCase() == course_code.value.toUpperCase()
          );
          if (ifcourseExist) {
            error_msgs.value = `Course Code: ${course_code.value} already exists...`;
          } else {
            error_msgs.value = "";
            checkingText.value = "";
          }
          isCourseAvailable.value = false;
        }
      });

      return {
        ...toRefs(courseForm),
        getters_program,
        saveCourseBtn,
        getters_savecourse,
        duplicateCourse,
        form,
        getters_abilities,
      };
    },
  };
</script>
