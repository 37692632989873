<template>
	<v-container fluid>
		<PageHeader>
			<span slot="courses" class="font-weight-light">ACADEMIC </span> COURSES
		</PageHeader>
		<CourseComponent />
	</v-container>
</template>
<script>
import PageHeader from "@/components/slots/PageHeader";
import CourseComponent from "@/components/Faculty/CourseComponent";
export default {
	components: { PageHeader, CourseComponent },
};
</script>